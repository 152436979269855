import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

interface ISEO {
  title?: string
  description?: string
  keywords?: string[]
}

function SEO({ title, description, keywords }: ISEO) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          ...siteMetaDataFragment
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const metaTitle = `${title} | ${site.siteMetadata.title}`
  const lang = `en`
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      meta={[
        {
          name: `robots`,
          content: `index,follow`,
        },
        {
          name: `author`,
          content: site.siteMetadata.author,
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(
        keywords
          ? {
              name: `keywords`,
              content: keywords.join(", "),
            }
          : []
      )}
    />
  )
}

export default SEO
