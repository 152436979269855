import { Link } from "gatsby"
import React from "react"

import { graphql, StaticQuery } from "gatsby"

// CSS
import "./header.scss"
import "./site-nav.scss"


export class Header extends React.Component {

  render() {
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              ...siteMetaDataFragment
            }
          }
        `}
        render={data => (
          <header className="site-header">
            <div className="site-header__content">
              <div className="site-header__title-container">
                <Link
                  className="site-header__title"
                  to="/">
                  { data.site.siteMetadata.title }
                </Link>
              </div>
              <nav className="site-nav">
                <ul className="site-nav__list">
                  <li className="site-nav__list-item">
                    <Link
                      className="site-nav__link"
                      to="/blog">
                      <span className="site-nav__link-label">Blog</span>
                    </Link>
                  </li>
                  <li className="site-nav__list-item">
                    <Link
                      className="site-nav__link"
                      to="/about">
                      <span className="site-nav__link-label">About</span>
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </header>
        )}
      />
    )
  }
}