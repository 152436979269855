import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { Helmet } from "react-helmet"

import { Header } from "../header/header"
import Footer from "../footer/footer"
import SEO from "../seo"

import SiteMetadata from "../../types/SiteMetaData"

import "./layout.scss"

interface ILayout {
  customClassName?: string
  linkedData?: Object
}

export default class Layout extends React.Component<ILayout> {

  generateStructuredData(siteMetadata: SiteMetadata) {
    return {
      "@context": "https://schema.org",
      "@type": "Website",
      "publisher": {
        "@type": "Organization",
        "name": siteMetadata.title,
        "logo": `${siteMetadata.siteUrl}/icons/icon-48x48.png`
      },
      "url": siteMetadata.siteUrl,
      "image": {
        "@type": "ImageObject",
        "url": `${siteMetadata.siteUrl}/icons/icon-48x48.png`,
        "width": 960,
        "height": 642
      },
      "description": siteMetadata.description,
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": siteMetadata.siteUrl
      }
    }
  }

  render(){
    const customClassName = this.props.customClassName ? `content content--${this.props.customClassName}` : "";
    return (
      <StaticQuery
        query={graphql`
          query {
            site {
              ...siteMetaDataFragment
            }
          }
        `}
        render={data => (
          <>
            <Helmet>
              <link href="https://fonts.googleapis.com/css?family=Raleway:400,700&display=swap" rel="stylesheet" />
              <script type="application/ld+json">{`${JSON.stringify(this.props.linkedData || this.generateStructuredData(data.site.siteMetadata))}`}</script>
            </Helmet>
            <SEO 
              title={this.props.title}
              description={this.props.linkedData ? this.props.linkedData.description : ""}
              keywords={this.props.linkedData? this.props.linkedData.keywords.split(", ") : ""} />
            <Header />
            <>
              <main className={`${customClassName} content`}>{this.props.children}</main>
            </>
            <Footer />
          </>
        )}
      />
    )
  }
}